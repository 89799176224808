<template>
  <section class="actions-block-recover">
    <div
      class="actions-block-recover__button"
      @click.stop="restore"
    >
      <r-icon
        fill="rocky"
        icon="update"
      />
      <span class="ml-2 sulguni rocky--text">
        Восстановить
      </span>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ActionsBlockRecover',
  inject: ['incident'],
  computed: {
  },
  methods: {
    ...mapActions('incident', ['restoreIncident']),
    restore() {
      this.restoreIncident({ id: this.incident.id })
        .then(() => {
          this.$rir.notification.send({
            title: 'Обращение успешно восстановлено',
            seconds: 5
          });
        })
        .catch(() => {
          this.$rir.notification.send({
            title: 'Обращение не восстановлено',
            seconds: 5
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.actions-block-recover {
  margin-left: auto;
  margin-right: 24px;
  position: relative;
  top: 44%;

  &__button {
    opacity: 0.72;
    display: flex;
    align-items: center;
    transition: opacity, 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
